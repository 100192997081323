<template>
  <div>
    <div class="title-item">
      <el-row>
        <el-col :span="12" class="title-item-left">
          <span>
            <p>待结算金额</p>
            <p>{{shopInfo.unsettle_money | unitPrice("￥")}}元</p>
          </span>
        </el-col>
        <el-col :span="12" class="title-item-right">
          <span>
            <p>账户余额</p>
            <p>{{shopInfo.withdrawal_money+shopInfo.unwithdrawal_money | unitPrice("￥")}}元</p>
          </span>
        </el-col>
      </el-row>
    </div>
    <!-- shopaccountList 账户与提现 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--提现申请时间-->
          <div class="conditions">
            <div>申请时间：</div>
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:320px"
              value-format="timestamp"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="{disabledDate(time) { return time.getTime() > Date.now() + 86400000 }}"
            ></el-date-picker>
            <!-- 提现状态选择 -->
            <el-select
              class="ipt-default"
              style="width:150px;margin-left:10px"
              placeholder="提现状态选择"
              v-model="advancedForm.settle_status"
              clearable
            >
              <!-- <el-option value="1" label="提现中"></el-option> -->
              <el-option value="2" label="提现成功"></el-option>
              <el-option value="3" label="提现失败"></el-option>
            </el-select>
            <el-button style="color: #ffffff; background-color: #008080" class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="settle_no" label="提现流水号" />
        <el-table-column prop="withdrawal_money" label="提现金额">
          <template slot-scope="scope">{{ scope.row.withdrawal_money | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="fee_money" label="手续费">
          <template slot-scope="scope">{{ scope.row.fee_money | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="提现申请时间">
          <template
            slot-scope="scope"
          >{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="提现状态">
          <template
            slot-scope="scope"
          >{{ scope.row.settle_status===1?"提现中": scope.row.settle_status===2?"提现成功":"提现失败"}}</template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" />
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
// import * as API_Distribution from "@/api/distribution";
import * as API_Account from "@/api/account";
// import { RegExp } from "@libs/common/utils";

export default {
  name: "shopaccountList",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        // 提现人员类型，1商户，2分销商
        mem_type: 1,
      },
      shopInfo: {},
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
        settle_status: "", // 分类
      },
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_List();
    API_Account.getShopsInfo().then((res) => {
      console.log(res);
      this.shopInfo = res;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    next();
  },
  methods: {
    /** 返回当天23:59:59 */
    returnTime() {
      const today = new Date()
      today.setTime(today.getTime())
      today.setHours(23)
      today.setMinutes(59)
      today.setSeconds(59)
      return today.getTime()
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        this.params.start_time = this.advancedForm.times[0] / 1000;
        this.params.end_time = this.advancedForm.times[1] / 1000;
      }
      delete this.params.times;
      this.params.settle_status = this.advancedForm.settle_status;
      this.GET_List();
    },

    /** 获取列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Account.getWithdrawalList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.title-item {
  color: #444444 !important;
  font-size: 15px !important;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 35px;
  margin-bottom: 24px;
  .title-item-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .title-item-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      margin-right: 20px;
    }
  }
}
.el-date-editor.el-input {
  width: 180px;
}

/deep/ .form-item-sex .el-form-item__content {
  width: 180px;
}

/deep/ .form-item-region .el-form-item__content {
  min-width: 180px;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
